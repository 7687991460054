var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider, } from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import palette from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
export default function ThemeProvider(_a) {
    var children = _a.children;
    var _b = useSettingsContext(), themeMode = _b.themeMode, themeDirection = _b.themeDirection;
    var themeOptions = useMemo(function () { return ({
        palette: palette(themeMode),
        typography: typography,
        shape: { borderRadius: 8 },
        direction: themeDirection,
        shadows: shadows(themeMode),
        customShadows: customShadows(themeMode),
    }); }, [themeDirection, themeMode]);
    var theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
    return (_jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsxs(MUIThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), _jsx(GlobalStyles, {}), children] })) })));
}
